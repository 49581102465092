export default {
  DATE_PICKER_TITLE: 'YYYY年MM月',
  PAGE_SIZE: 10,
  MAX_PAGE_SIZE: 9999,
  ZIPCODE_URL: 'https://www.post.japanpost.jp/zipcode/',
  REGEX_ONLY_NUMBER_AND_DOT: /^[0-9]*\.?[0-9]*$/,
  REGEX_ONLY_NUMBER_AND_COMMA: /^[\,0-9]*$/,
  REGEX_KANA: /^([ァ-ンｧ-ﾝﾞﾟ]|ー|ヴ|ｳﾞ| )+$/,
  MAX_FILE_UPLOAD: 5,
  DEFAULT_COUNT_USER: 4,
  NO_REPEAT: 1,
  NO_NOTIFY: 1,
  DEFAULT_POPUP_WIDTH: 650,
};

export enum STATUS {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum GENDER {
  MALE = 1,
  FEMALE = 0,
}

export enum USER_ROLE {
  ADMIN_CMS_SYSTEM = '1',
  ADMIN_CMS_COMPANY = '2',
  MANAGER_ROLE = '3',
  USER_ROLE = '4',
}

export enum REAL_ESTATE_STATUS {
  WAITING = 1,
  APPROVED = 2,
  REJECTED = 3,
}

export enum REAL_ESTATE_TYPE {
  LAND = 1,
  DETACH_HOUSE = 2,
  APARMTENT = 3,
}

export enum TYPE_SORT_CUSTOMER {
  RES_YEAR_ASC = 1,
  RES_YEAR_DESC = 2,
  AGE_ASC = 3,
  AGE_DESC = 4,
  REGIS_DATE_ASC = 5,
  REGIS_DATE_DESC = 6,
}
export enum PURCHASE_STATUS {
  PURCHASE = 1,
  SALE = 2,
}

export const FORMAT_DATE2 = 'YYYY/MM/DD hh:mm';
export const FORMAT_DATE1 = 'YYYY/MM/DD';

export const DATE_FORMAT = 'YYYY年MM月DD日';
export const FORMAT_DATE_TIME_24 = 'YYYY-MM-DD HH:mm:ss';

export enum MASTER_TYPE {
  MASTER_ADVERTISING_FORM = 1,
  MASTER_ADVERTISING_WEB = 2,
  MASTER_BROKERAGE_FEE = 3,
  MASTER_CONTACT_METHOD = 4,
  MASTER_CONTACT_REASON = 5,
  MASTER_CONTACT_TYPE = 6,
  MASTER_FIELDL = 7,
  MASTER_NOTIFY_CALENDAR = 8,
  MASTER_PHASE_PROJECT = 9,
  MASTER_POSITION = 10,
  MASTER_POSTAL_CODE = 11,
  MASTER_PRICE = 12,
  MASTER_PROPERTY_BUILDING_STRUCTURE = 13,
  MASTER_PROPERTY_CONTRACT_TYPE = 14,
  MASTER_PROPERTY_CURRENT_SITUATION = 15,
  MASTER_PROPERTY_TYPE = 16,
  MASTER_PROVINCE = 17,
  MASTER_PURCHASE_PURPOSE = 18,
  MASTER_RAIL = 19,
  MASTER_RESIDENCE_YEAR = 20,
  MASTER_SALE_PURPOSE = 21,
  MASTER_SCHEDULE_REPEATl = 22,
  MASTER_STATION = 23,
}
