import { getUserProfile } from 'api/profile';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

export default function useProfile() {
  const history = useHistory();
  const res: any = useQuery(['get_user_profile'], () => getUserProfile(), {
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    onError: (err: any) => {
      if (err.response.status === 401) {
        history.push(`/login`);
      }
    },
  });
  return res;
}
