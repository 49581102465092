import React from 'react';
import { Button, message, Tooltip } from 'antd';
import Cookies from 'js-cookie';
import i18n from 'i18next';
import common, { FORMAT_DATE1, MASTER_TYPE } from './constant';
import { IGetMasterParams } from 'types';
import editIcon from 'assets/images/edit.svg';
import deleteIcon from 'assets/images/delete.svg';
import tableIcon from 'assets/images/tableIcon.svg';
import dayjs from 'dayjs';
export function logout() {
  Cookies.remove('dataLogin');
  Cookies.remove('token');
  Cookies.remove('refreshToken');
  Cookies.remove('unconfirmInterview');
}

export function regexPassword() {
  return new RegExp(/^(?=.*[A-Za-z])(?=.*\d)()[A-Za-z\d]{8,20}$/); // at least one character, at least one number, at least 8 character
}

export function nonSymbol() {
  return new RegExp(/^[^!"#$%&'()\*\+\-\.,\/:;<=>?@\[\\\]^_`{|}~]+$/);
}

export function regexNumber() {
  return new RegExp(/^[0-9]+$/);
}

export function maxLength50() {
  return new RegExp(/^.{1,50}$/);
}

export function getRole() {
  return localStorage.getItem('role');
}

export function formatPostalCode(value: any) {
  return `${value.substr(0, 3)}-${value.substr(3, value.length + 1)}`;
}

export function formatMoney(value: any) {
  return value % 10000 === 0 && value / 10000 > 0
    ? `${(value / 10000).toLocaleString('ja-JP')}万円`
    : `${value.toLocaleString('ja-JP')}円`;
}

export function formatPhone(value: any) {
  let phone = value?.replace(/[^\d]/g, '');
  let result =
    phone?.length == 10
      ? `${phone?.substr(0, 3)}-${phone?.substr(3, 3)}-${phone?.substr(6)}`
      : `${phone?.substr(0, 3)}-${phone?.substr(3, 4)}-${phone?.substr(7)}`;
  return result;
}

export function validateImageType(file: File, isMultiUpload) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
  const isValidSize = file.size / 1024 / 1024 < 10;
  if (!isJpgOrPng || !isValidSize) {
    isMultiUpload
      ? message.error(i18n.t('validate.invalidImagesUpload'))
      : message.error(i18n.t('validate.invalidImageUpload'));
    return;
  }
  return true;
}

export function validateFileUpload(file: File) {
  const type =
    file.name.includes('.png') ||
    file.name.includes('.csv') ||
    file.name.includes('.pdf') ||
    file.name.includes('.docx') ||
    file.name.includes('.jpeg') ||
    file.name.includes('.jpg');
  const isValidSize = file.size / 1024 / 1024 < 10;
  if (!type || !isValidSize) {
    message.error(i18n.t('validate.invalidFileUpload'));
    return;
  }
  return true;
}

export function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function validateRatio(rule: any, value: any, callback: any, step: any) {
  if (value && step === 2) {
    if (!common.REGEX_ONLY_NUMBER_AND_DOT.test(value) || value > 100 || value < 0) {
      return callback(i18n.t('validate.type_less_than_100'));
    } else callback();
  }
  callback();
}

export function validateArea(rule: any, value: any, callback: any, step: any) {
  if (value && step === 2) {
    if (!common.REGEX_ONLY_NUMBER_AND_DOT.test(value) || value > 999999 || value < 0) {
      return callback(i18n.t('validate.inputInvalid'));
    } else callback();
  }
  callback();
}

export function getKeywordSearch(path: any, currentKey: any, nextKey: any) {
  let keyword = path.slice(
    path.indexOf(currentKey) + currentKey.length,
    nextKey === '' ? path.length : path.indexOf(nextKey)
  );
  return keyword !== '' ? keyword : undefined;
}

export function getFullname(firstName: string | null, lastName: string | null) {
  firstName = firstName ?? '';
  lastName = lastName ?? '';
  return `${lastName}${firstName ? ` ${firstName}` : ''}`;
}

export function getMasterObjs({ t, handleEdit, handleDelete }: IGetMasterParams) {
  const idColumn = {
    title: 'ID',
    width: '10%',
    key: 'id',
    dataIndex: 'id',
  };
  const nameColumn = {
    title: `${t('common.title')}`,
    width: '70%',
    key: 'name',
    dataIndex: 'name',
  };
  const createdAtColumn = {
    title: `${t('common.createData')}`,
    width: '15%',
    key: 'create',
    dataIndex: 'created_at',
    render: (createdAt) => {
      return createdAt && dayjs(createdAt).isValid() ? dayjs(createdAt).format(FORMAT_DATE1) : '';
    },
  };
  const actionColumn = {
    key: 'action',
    width: '15%',
    render: (row: any) => {
      return (
        <div className="d-flex">
          <Tooltip title={t('common.edit')}>
            <img
              onClick={() => handleEdit(row)}
              src={editIcon}
              alt=""
              tabIndex={-1}
              className="mr-6 ml-auto cursor-pointer"
            />
          </Tooltip>
          <Tooltip title={t('common.delete')}>
            <img onClick={() => handleDelete(row)} src={deleteIcon} alt="" tabIndex={-1} className="cursor-pointer" />
          </Tooltip>
        </div>
      );
    },
  };
  const Icon = {
    key: 'action',
    width: '2%',
    render: (row: any) => {
      return (
        <div className="d-flex">
          <Tooltip title={t('common.')}>
            <img src={tableIcon} alt="" tabIndex={-1} className="ml-auto cursor-pointer" />
          </Tooltip>
        </div>
      );
    },
  };
  const contactMethodColumn = {
    title: `${t('common.title')}`,
    with: '70%',
    key: 'contact_method',
    dataIndex: 'contact_method',
  };
  const purposeColumn = {
    title: `${t('common.title')}`,
    width: '70%',
    dataIndex: 'purchase_purpose',
    key: 'purchase_purpose',
  };

  const saleColumn = {
    title: `${t('common.title')}`,
    width: '70%',
    dataIndex: 'sale_purpose',
    key: 'sale_purpose',
  };

  const commonColumns = [idColumn, nameColumn, createdAtColumn, actionColumn];
  const contactMethodColumns = [idColumn, contactMethodColumn, createdAtColumn, actionColumn];
  const purchasePurposeColumns = [idColumn, purposeColumn, createdAtColumn, actionColumn];
  const salePurposeColumns = [idColumn, saleColumn, createdAtColumn, actionColumn];
  return [
    { id: MASTER_TYPE.MASTER_ADVERTISING_FORM, name: t('common.advertisingForm'), columns: commonColumns },
    { id: MASTER_TYPE.MASTER_CONTACT_METHOD, name: t('common.contactMethodTab'), columns: contactMethodColumns },
    { id: MASTER_TYPE.MASTER_CONTACT_REASON, name: t('common.contactReason'), columns: commonColumns },
    // { id: MASTER_TYPE.MASTER_CONTACT_TYPE, name: t('common.contactType'), columns: commonColumns },
    { id: MASTER_TYPE.MASTER_POSITION, name: t('common.position'), columns: commonColumns },
    {
      id: MASTER_TYPE.MASTER_PROPERTY_BUILDING_STRUCTURE,
      name: t('common.propertyBuildingStructure'),
      columns: commonColumns,
    },
    { id: MASTER_TYPE.MASTER_PROPERTY_CONTRACT_TYPE, name: t('common.propertyContractType'), columns: commonColumns },
    {
      id: MASTER_TYPE.MASTER_PROPERTY_CURRENT_SITUATION,
      name: t('common.propertyCurrentSituation'),
      columns: commonColumns,
    },
    { id: MASTER_TYPE.MASTER_PROPERTY_TYPE, name: t('common.propertyType'), columns: commonColumns },
    { id: MASTER_TYPE.MASTER_PURCHASE_PURPOSE, name: t('common.purchasePurposeTab'), columns: purchasePurposeColumns },
    { id: MASTER_TYPE.MASTER_SALE_PURPOSE, name: t('common.salePurpose'), columns: salePurposeColumns },
    { id: MASTER_TYPE.MASTER_PHASE_PROJECT, name: t('common.masterPhase'), columns: commonColumns },
  ];
}

export function getDataSource(data, activeKey) {
  const empty = { dataSource: [], total: 0 };
  if (!data) return empty;
  switch (activeKey) {
    case MASTER_TYPE.MASTER_ADVERTISING_FORM:
    case MASTER_TYPE.MASTER_CONTACT_METHOD:
    case MASTER_TYPE.MASTER_CONTACT_REASON:
    case MASTER_TYPE.MASTER_POSITION:
    case MASTER_TYPE.MASTER_PROPERTY_BUILDING_STRUCTURE:
    case MASTER_TYPE.MASTER_PROPERTY_CONTRACT_TYPE:
    case MASTER_TYPE.MASTER_PROPERTY_CURRENT_SITUATION:
    case MASTER_TYPE.MASTER_PROPERTY_TYPE:
    case MASTER_TYPE.MASTER_PURCHASE_PURPOSE:
    case MASTER_TYPE.MASTER_SALE_PURPOSE:
    case MASTER_TYPE.MASTER_PHASE_PROJECT:
      return {
        dataSource: data?.data ?? [],
        total: data?.data?.length ?? 0,
      };
    case MASTER_TYPE.MASTER_POSTAL_CODE:
      return {
        dataSource: data?.data?.postal_codes ?? [],
        total: data?.data?.total_items ?? 0,
      };

    default:
      return empty;
  }
}
