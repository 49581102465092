import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthWrapper from 'wrappers/AuthWrapper';

const FirstStepLogin = lazy(() => import('pages/Authen/Login/FirstStep'));
const SecondStepLogin = lazy(() => import('pages/Authen/Login/SecondStep'));
const SendEmailStep = lazy(() => import('pages/Authen/ForgotPassword/SendEmailStep'));
const VerifyCodeStep = lazy(() => import('pages/Authen/ForgotPassword/VerifyCodeStep'));
const ResetPassword = lazy(() => import('pages/Authen/ForgotPassword/ResetPasswordStep'));

export default function AppWrapper() {
  return (
    <div className="root-wrapper">
      <Switch>
        <Route exact path="/login" component={FirstStepLogin} />
        <Route exact path="/login-verify" component={SecondStepLogin} />
        <Route exact path="/forgot-password" component={SendEmailStep} />
        <Route exact path="/forgot-password-verify" component={VerifyCodeStep} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route path="/" component={AuthWrapper} />
      </Switch>
    </div>
  );
}
