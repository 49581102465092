import React, { lazy, Suspense } from 'react';
import Cookies from 'js-cookie';
import PageHeader from 'components/PageHeader';
import SideNav from 'components/SideNav';
import { Redirect, Route, Switch } from 'react-router-dom';
import styles from './styles.module.scss';
import useProfile from 'hooks/useProfile';
import NotFound from 'pages/NotFound';
import lazyWithRetry from 'helper/lazyWithRetry';

const CompanyList = lazyWithRetry(() => import('pages/Companies/List'));
const EmployeeDetail = lazyWithRetry(() => import('pages/Companies/CompanyDetail/EmployeeDetail'));
const DetailDepartment = lazyWithRetry(() => import('pages/Companies/CompanyDetail'));
const ContactUsList = lazyWithRetry(() => import('pages/ContactUs'));
const ContactUs = lazyWithRetry(() => import('pages/ContactUs/Detail'));
const Master = lazyWithRetry(() => import('pages/Master/index'));
const Dashboard = lazyWithRetry(() => import('pages/Dashboard'));
const Profile = lazyWithRetry(() => import('pages/Profile'));

interface IProps {
  me?: any;
  refetchMe: any;
}

const AuthContext = React.createContext<IProps>({
  me: null,
  refetchMe: (options) => null,
});

export default function PageWrapper() {
  const isAuthenticated = !!Cookies.get('token');
  const { data, refetch } = useProfile();

  if (!isAuthenticated) return <Redirect to="/login" />;
  return (
    <AuthContext.Provider value={{ me: data?.data, refetchMe: refetch }}>
      <div className={styles.pageWrapper}>
        <SideNav />
        <div className={styles.mainWrapper}>
          <PageHeader />
          <div className={styles.pageContent}>
            <Suspense fallback={null}>
              <Switch>
                <Route path="/company/:id" exact component={DetailDepartment} />
                <Route path="/company/:id/employee/:id" exact component={EmployeeDetail} />
                <Route path="/companies" exact component={CompanyList} />
                <Route path="/contact-us" exact component={ContactUsList} />
                <Route path="/contact-us/:id" exact component={ContactUs} />
                <Route path="/master-data" exact component={Master} />
                {/* <Route path="/profile" exact component={Profile} /> */}
                <Route path="/" exact component={Dashboard} />
                <Route component={NotFound} />
              </Switch>
            </Suspense>
          </div>
        </div>
      </div>
    </AuthContext.Provider>
  );
}
