import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import { Menu, Dropdown } from 'antd';
import { useQueryClient } from 'react-query';
import useProfile from 'hooks/useProfile';
import { useTranslation } from 'react-i18next';
import defaultAva from 'assets/images/default-avatar.jpg';
import PopupProfileDetail from 'components/PopupProfileDetail';
import PopupChangePassword from 'components/PopupChangePassword';
import RoleDisplayWithIcon from 'components/User/RoleDisplayWithIcon';

enum ShowingModal {
  None,
  ProfileDetail,
  ProfileEdit,
  ChangePassword,
}

export default function PageHeader() {
  const { t } = useTranslation();
  const history = useHistory();
  const userInfo = useProfile();
  localStorage.setItem('role', userInfo?.data?.data?.role);
  const [showModal, setShowModal] = useState(ShowingModal.None);

  const handleLogout = () => {
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    localStorage.clear();
    sessionStorage.clear();
    history.push('/login');
  };

  const handleCloseModal = () => {
    setShowModal(ShowingModal.None);
  };

  const handleSwitchProfileDetail = () => {
    setShowModal(ShowingModal.ProfileDetail);
  };

  const handleSwitchChangePassword = () => {
    setShowModal(ShowingModal.ChangePassword);
  };

  const menu = (
    <Menu style={{ minWidth: 200 }}>
      <Menu.Item key="1" onClick={handleSwitchProfileDetail}>
        {t('common.customerInfo2')}
      </Menu.Item>
      <Menu.Item key="3" onClick={handleLogout}>
        {t('common.logout')}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.menuWrapper}>
        <div className={styles.menuItem}>
          <Dropdown overlay={menu} trigger={['click']}>
            <div className={styles.userInfo}>
              <span>{userInfo?.data?.data?.email}</span>
              <img className={styles.iconAvatar} src={userInfo?.data?.data?.avatar ?? defaultAva} alt="" />
            </div>
          </Dropdown>
        </div>
      </div>
      <PopupProfileDetail
        className={styles.profileDetail}
        visible={showModal === ShowingModal.ProfileDetail}
        onClose={handleCloseModal}
        onShowModalChangePassword={handleSwitchChangePassword}
      />
      <PopupChangePassword
        className={styles.passwordChange}
        visible={showModal === ShowingModal.ChangePassword}
        onClose={handleCloseModal}
        onCancel={handleSwitchProfileDetail}
      />
    </div>
  );
}
