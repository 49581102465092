import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { Descriptions, Image, Upload, message } from 'antd';
import classNames from 'classnames';

import editAvt from 'assets/images/edit2.svg';
// import editCircle from 'assets/images/editCircle.svg';
import defaultAva from 'assets/images/default-avatar.jpg';
import { updateAvatar } from 'api/profile';

import { validateImageType } from 'common/function';

import styles from './styles.module.scss';

const UserInfo = ({ user, className = '', canEdit = true, onShowModalChangePassword }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { data: userInfo } = user;

  const [thumbList, setThumbList] = useState(user?.data.avatar ?? defaultAva);
  const [loadingAvt, setLoadingAvt] = useState(false);

  const labelStyle = {
    width: '150px',
    fontSize: '17px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#646464',
  };

  const contentStyle = {
    fontSize: '17px',
    fontWeight: 700,
    lineHeight: '21px',
  };

  const handleChangeAvatar = async (file: any) => {
    setLoadingAvt(true);
    let formData = new FormData();
    if (validateImageType(file.file, false)) {
      formData.append('avatar', file.file);
      const res = await updateAvatar(formData, userInfo?.id);

      if (res.success) {
        setThumbList(res?.data);
        queryClient.invalidateQueries('get_user_profile');
      } else {
        message.error(res.message);
      }
      setLoadingAvt(false);
    }
  };

  const handleShowModalChangePassword = () => {
    onShowModalChangePassword();
  };

  // const onEditProfileManager = () => {
  // }

  return (
    <div className={classNames(styles.infoDetail, className)}>
      <div className={styles.infoAvatar}>
        <div className={styles.updateAvatar}>
          {canEdit && (
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader flex-center"
              showUploadList={false}
              accept="image/png, image/jpeg, image/jpg"
              customRequest={handleChangeAvatar}
            >
              <div className={styles.uploadBtn}>
                <img alt="add" src={editAvt} />
              </div>
            </Upload>
          )}
          {loadingAvt ? <LoadingOutlined /> : <Image src={thumbList} className={styles.thumbnail} alt="" />}
        </div>
        <div className={styles.infoUser}>
          <span className={styles.name}>{userInfo?.username}</span>
          {/* <span className={styles.position}>{userInfo?.furigana}</span> */}
        </div>
        <div className={styles.title}>
          <span>{t('common.basicInfo')}</span>
          {/* {canEdit && (
            <div className={styles.editCircle} onClick={onEditProfileManager}>
              <img src={editCircle} alt="" />
            </div>
          )} */}
        </div>
      </div>
      <div className={styles.line}></div>
      <div className={styles.infoBasic}>
        <Descriptions className={styles.descriptions} colon={false} column={1} labelStyle={labelStyle}>
          <Descriptions.Item label={t('common.furigana')} contentStyle={contentStyle}>
            {userInfo?.furigana || ''}
          </Descriptions.Item>
          <Descriptions.Item label={`${t('common.position')}`} contentStyle={contentStyle}>
            {userInfo?.position_name || ''}
          </Descriptions.Item>
          <Descriptions.Item label={t('common.mail')} contentStyle={contentStyle}>
            {userInfo?.email || ''}
          </Descriptions.Item>
          <Descriptions.Item label={t('common.phoneNumber')} contentStyle={contentStyle}>
            {userInfo?.phone || 0}
          </Descriptions.Item>
        </Descriptions>
        <div className="text-right">
          <span className={styles.changePassword} onClick={handleShowModalChangePassword}>
            {t('common.NewPassword')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
