import React from 'react';
import CustomModal from 'components/Atoms/CustomModal';
import UserInfo from 'components/UserInfo';
import useProfile from 'hooks/useProfile';
import classNames from 'classnames';
import styles from './styles.module.scss';

const PopupProfileDetail = ({ className = '', visible, onClose, onShowModalChangePassword, ...props }) => {
  const { data: profileData } = useProfile();

  const handleCloseModal = () => {
    onClose();
  };
  return (
    <CustomModal
      visible={visible}
      onClose={handleCloseModal}
      {...props}
      className={classNames(styles.profileDetailCustom, className)}
    >
      <UserInfo user={profileData} className={'border-none'} onShowModalChangePassword={onShowModalChangePassword} />
    </CustomModal>
  );
};

export default PopupProfileDetail;
