import React, { useState } from 'react';
import Cookies from 'js-cookie';
import avatarImg from 'assets/images/avatar.svg';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import { Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import questionMarkIcon from '../../assets/images/questionMark.svg';
import errorIcon from '../../assets/images/errorIcon.svg';

export default function PopupConfirm() {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isError = true;
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <div>
      <Modal
        width={886}
        footer={false}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        className={styles.popupContainer}
      >
        <div className={styles.popupTitle}>アカウント更新確認</div>
        <img alt="questionMark" src={isError ? errorIcon : questionMarkIcon} />
        <div className={styles.popupContent}>アカウントを更新してもよろしいでしょうか。</div>
        <div className={styles.btnGroup}>
          <Button className="btn-sub-cancel" onClick={handleCancel}>
            キャンセル
          </Button>
          <Button className="btn-sub-ok" onClick={handleOk}>
            確認
          </Button>
        </div>
      </Modal>
    </div>
  );
}
