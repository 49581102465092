import configs from 'config';
import { message } from 'antd';
import i18n from 'i18next';
import common from 'common/constant';

export const handleErrorMessage = (error: any) => {
  message.destroy();
  message.error(getErrorMessage(error));
  if (configs.APP_ENV !== 'prod') {
    // tslint:disable-next-line: no-console
    console.log(error);
  }
};

export const getErrorMessage = (error: any) => {
  return error?.response?.data?.message || i18n.t('common.loginFailed');
};

export function getFullAddress(row: any) {
  if (!row) return '';
  const province = row?.province ? `${row.province} ` : '';
  const district = row?.district ? `${row.district} ` : '';
  const address = row?.address ?? '';
  return province + district + address;
}

export function formatPhone(value: any) {
  let phone = value?.replace(/[^\d]/g, '');
  let result =
    phone?.length === 10
      ? `${phone?.substr(0, 3)}-${phone?.substr(3, 4)}-${phone?.substr(7)}`
      : `${phone?.substr(0, 3)}-${phone?.substr(3, 4)}-${phone?.substr(7)}`;
  return result;
}

export function fullwidthToHalfwidthNumber(value: any) {
  let result = value.replace(/[\uff01-\uff5e]/g, function (ch: any) {
    return String.fromCharCode(ch.charCodeAt(0) - 0xfee0);
  });
  return result;
}

export function regexPassword() {
  return new RegExp(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,20}$/); // at least one character, at least one number, at least 8 character
}

export function getLastPage(totalCount: number) {
  return (totalCount + 1) % common.PAGE_SIZE
    ? parseInt(`${(totalCount + 1) / common.PAGE_SIZE}`) + 1
    : (totalCount + 1) / common.PAGE_SIZE;
}

export function convertObjToFormData(item) {
  const formData = new FormData();

  for (const key in item) {
    formData.append(key, item[key]);
  }
  return formData;
}
