import React, { useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import CustomModal from 'components/Atoms/CustomModal';
import { useForm } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import { fullwidthToHalfwidthNumber, handleErrorMessage, regexPassword } from 'helper';
import classNames from 'classnames';
import { updatePassWord } from 'api/profile';

const PopupChangePassword = ({ className = '', visible, onClose, onCancel, ...props }) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitForm = async (formValue: any) => {
    const payload = {
      password: formValue.currentPassword,
      new_password: formValue.password,
      re_new_password: formValue.passwordConfirm,
    };

    try {
      setIsLoading(true);
      const res = await updatePassWord(payload);

      if (res.success) {
        message.success(res.message);
        form.resetFields();
        onClose();
      } else {
        message.error(res.message);
      }
    } catch (error) {
      handleErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    form.resetFields();
    onClose();
  };

  const handleCancelModal = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <CustomModal
      title={t('common.NewPassword')}
      visible={visible}
      onClose={handleCloseModal}
      {...props}
      className={className}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmitForm} className="p-22px">
        <Form.Item
          name="currentPassword"
          label={t('common.CurrentPassword')}
          rules={[
            {
              required: true,
              message: t('validate.generalRequired'),
            },
            {
              pattern: regexPassword(),
              message: t('validate.validatePassword'),
            },
          ]}
        >
          <Input.Password
            autoComplete="new-password"
            placeholder={t('common.CurrentPassword')}
            onChange={(e) => {
              form.setFieldsValue({
                currentPassword: fullwidthToHalfwidthNumber(e.target.value),
              });
            }}
          />
        </Form.Item>
        <Form.Item
          name="password"
          label={t('common.NewPasswordRegister')}
          rules={[
            {
              required: true,
              message: t('validate.generalRequired'),
            },
            {
              pattern: regexPassword(),
              message: t('validate.validatePassword'),
            },
          ]}
        >
          <Input.Password
            autoComplete="new-password"
            placeholder={t('common.NewPasswordRegister')}
            onChange={(e) => {
              form.setFieldsValue({
                password: fullwidthToHalfwidthNumber(e.target.value),
              });
            }}
          />
        </Form.Item>
        <Form.Item
          name="passwordConfirm"
          label={t('common.reEnterPassword')}
          rules={[
            {
              required: true,
              message: '',
            },
            {
              validator(_, value) {
                const passwordComfirm = value.trim();
                const password = form.getFieldValue('password');

                if (passwordComfirm === '') return Promise.reject(t('validate.generalRequired'));

                if (!passwordComfirm.match(regexPassword())) return Promise.reject(t('validate.validatePassword'));

                if (password?.trim() !== passwordComfirm) return Promise.reject(t('validate.passwordNotMatch'));

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input.Password
            autoComplete="new-password"
            placeholder={t('common.reEnterPassword')}
            onChange={(e) => {
              form.setFieldsValue({
                passwordConfirm: fullwidthToHalfwidthNumber(e.target.value),
              });
            }}
          />
        </Form.Item>

        <Form.Item>
          <div>
            <Button onClick={handleCancelModal} className={classNames('btn-sub-cancel')} disabled={isLoading}>
              {t('common.cancel')}
            </Button>
            <Button htmlType="submit" className={classNames('btn-sub-ok')} loading={isLoading}>
              {t('common.register')}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </CustomModal>
  );
};

export default PopupChangePassword;
