import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import classNames from 'classnames';
import styles from './styles.module.scss';
import useToggleSideNav from 'hooks/useToggleSideNav';
import logo from 'assets/images/second_logo.svg';
import { useTranslation } from 'react-i18next';
import GenerateSvgIcon from 'common/icon';
import { USER_ROLE } from 'common/constant';
import { getRole } from 'common/function';

const { SubMenu } = Menu;

export default function SideNav() {
  const { t } = useTranslation();
  const collapsed = useToggleSideNav();
  const history = useHistory();
  const location = useLocation();
  const role = getRole();
  const [selectedKey, setSelectedKey] = useState('1');
  const [tabActived, setTabActived] = useState('1');
  const routes = [
    {
      key: '4',
      text: t('common.Dashboard'),
      url: '/',
      icon: <GenerateSvgIcon type={13} tabActived={tabActived} />,
    },
    {
      key: '1',
      text: t('common.companyManagement'),
      url: '/companies',
      icon: <GenerateSvgIcon type={12} tabActived={tabActived} />,
    },
    {
      key: '2',
      text: t('common.adminInformation'),
      url: '/contact-us',
      icon: <GenerateSvgIcon type={3} tabActived={tabActived} />,
    },
    {
      key: '3',
      text: t('common.masterData'),
      url: '/master-data',
      icon: <GenerateSvgIcon type={10} tabActived={tabActived} />,
    },
  ];
  const handleClickSideNav = (e: any) => {
    switch (e.key) {
      case '1':
        history.push('/companies');
        break;
      case '2':
        history.push('/contact-us');
        break;
      case '3':
        history.push('/master-data');
        break;
      case '4':
        history.push('/');
        break;
    }
  };

  useEffect(() => {
    routes.forEach((route) => {
      if (location.pathname.startsWith('/company')) {
        setSelectedKey('1');
        setTabActived('1');
      } else if (location.pathname.startsWith(route.url || '###')) {
        setSelectedKey(route.key);
        setTabActived(route.key);
      }
    });
  }, [location.pathname]);

  return (
    <div
      className={classNames({
        [styles.sideNav]: true,
        [styles.sideNavCollapsed]: collapsed,
      })}
      style={{ width: collapsed ? 80 : 250, transition: 'width 0.3s' }}
    >
      <Link className={styles.logo} to="companies">
        <img height={50} alt="logo" src={logo} />
      </Link>
      <Menu
        selectedKeys={[selectedKey]}
        defaultOpenKeys={[]}
        mode="inline"
        inlineCollapsed={collapsed}
        className={styles.sideNavMenu}
      >
        {routes.map((route: any) => {
          if (route.children) {
            return (
              <SubMenu key={route.key} icon={route.icon} title={route.text}>
                {route.children?.map((childRoute: any) => (
                  <Menu.Item key={childRoute.key}>
                    <Link to={childRoute.url}>{childRoute.text}</Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            );
          }

          return (
            <Menu.Item key={route.key} icon={route.icon} onClick={(e) => handleClickSideNav(e)}>
              <Link to={route.url}>{route.text}</Link>
            </Menu.Item>
          );
        })}
      </Menu>
      <p className={styles.powerBy}>Power by SUNS GROUP</p>
    </div>
  );
}
