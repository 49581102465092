import React from 'react';
import { Modal } from 'antd';
import classNames from 'classnames';
import closeCircleIcon from 'assets/images/close-circle-icon.svg';
import styles from './styles.module.scss';

const CustomModal = ({ title = '', className = '', children, onClose, ...props }) => {
  return (
    <Modal closable={false} footer={false} className={classNames(styles.customModal, className)} {...props}>
      <div className="header d-flex">
        <div className={classNames('w-100 text-center p-22px', styles.title, `${!title ? 'd-none' : ''}`)}>
          <span>{title}</span>
        </div>
        <div className={classNames('cursor-pointer absolute', styles.closeBtn)} onClick={onClose}>
          <img alt="close" src={closeCircleIcon} />
        </div>
      </div>
      {children}
    </Modal>
  );
};

export default CustomModal;
